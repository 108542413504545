import React from "react"
import cx from "classnames"
import Scroll from "react-scroll"

import Reveal from "./reveal"
import Logo from "../images/svg/FC_Logo.svg"
import styles from "./about.module.css"

let ScrollElement = Scroll.Element

const About = () => {
  return (
    <ScrollElement name="about">
      <section className={styles.about}>
        <Reveal>
          <p>
            <Logo /> is a design-minded web development studio led by{" "}
            <a
              href="https://davidgross.studio"
              target="_blank"
              rel="noopener noreferrer"
            >
              David Gross
            </a>
            . Our specialty is delivering pixel-perfect design implementations
            powered by industry-leading web technologies.
          </p>
        </Reveal>
      </section>
    </ScrollElement>
  )
}

About.propTypes = {}

About.defaultProps = {}

export default About
