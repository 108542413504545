import React from "react"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"

const Reveal = ({ children }) => {
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0,
  })

  const variants = {
    hide: {
      opacity: 0,
      y: 36,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        ease: "circOut",
      },
    },
  }

  return (
    <motion.div
      initial="hide"
      animate={inView ? "show" : "hide"}
      variants={variants}
      ref={ref}
    >
      {children}
    </motion.div>
  )
}

Reveal.propTypes = {}

Reveal.defaultProps = {}

export default Reveal
