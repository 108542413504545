import React from "react"
import cx from "classnames"
import SVG from "react-inlinesvg"

import Reveal from "./reveal"
import styles from "./partners.module.css"

const Partners = () => {
  return (
    <section className={styles.partners}>
      <Reveal>
        <h4 className={cx(styles.label, "mono")}>Select Collaborators</h4>
        <div className={styles.logoGrid}>
          <div className={styles.logoGridItem}>
            <a
              href="https://pentagram.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Pentagram"
            >
              <div className={styles.logoContainer}>
                <SVG src={`/Pentagram.svg`} />
              </div>
            </a>
          </div>
          <div className={styles.logoGridItem}>
            <a
              href="https://littleisland.org"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Little Island"
            >
              <div className={styles.logoContainer}>
                <SVG src={`/LittleIsland.svg`} />
              </div>
            </a>
          </div>
          <div className={styles.logoGridItem}>
            <a
              href="https://harrys.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Harry's"
            >
              <div className={styles.logoContainer}>
                <SVG src={`/Harrys.svg`} />
              </div>
            </a>
          </div>
          <div className={styles.logoGridItem}>
            <a
              href="https://moma.org"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="MoMA"
            >
              <div className={styles.logoContainer}>
                <SVG className={styles.containHeight} src={`/MoMA.svg`} />
              </div>
            </a>
          </div>
          <div className={styles.logoGridItem}>
            <a
              href="https://studioscissor.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Scissor"
            >
              <div className={styles.logoContainer}>
                <SVG src={`/Scissor.svg`} />
              </div>
            </a>
          </div>
          <div className={styles.logoGridItem}>
            <a
              href="https://butterstudio.co"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Butter"
            >
              <div className={styles.logoContainer}>
                <SVG className={styles.containHeight} src={`/Butter.svg`} />
              </div>
            </a>
          </div>
          <div className={styles.logoGridItem}>
            <a
              href="https://districtvision.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="District Vision"
            >
              <div className={styles.logoContainer}>
                <SVG src={`/DistrictVision.svg`} />
              </div>
            </a>
          </div>
          <div className={styles.logoGridItem}>
            <a
              href="https://fieldsgrade.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Fields Grade"
            >
              <div className={styles.logoContainer}>
                <SVG src={`/FieldsGrade.svg`} />
              </div>
            </a>
          </div>
          <div className={styles.logoGridItem}>
            <a
              href="https://theagnes001.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="The Agnes"
            >
              <div className={styles.logoContainer}>
                <SVG src={`/TheAgnes.svg`} />
              </div>
            </a>
          </div>
          <div className={styles.logoGridItem}>
            <a
              href="https://twelvenyc.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="And/Or"
            >
              <div className={styles.logoContainer}>
                <SVG src={`/TwelveNYC.svg`} />
              </div>
            </a>
          </div>
          <div className={styles.logoGridItem}>
            <a
              href="https://and-or.co"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="And/Or"
            >
              <div className={styles.logoContainer}>
                <SVG className={styles.containHeight} src={`/AndOr.svg`} />
              </div>
            </a>
          </div>
          <div className={styles.logoGridItem}>
            <a
              href="https://emme.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Emme"
            >
              <div className={styles.logoContainer}>
                <SVG className={styles.containHeight} src={`/Emme.svg`} />
              </div>
            </a>
          </div>
        </div>
      </Reveal>
    </section>
  )
}

export default Partners
