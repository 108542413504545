import React from "react"
import Scroll from "react-scroll"

import Reveal from "./reveal"

import styles from "./contact.module.css"

let ScrollElement = Scroll.Element

const Contact = () => {
  return (
    <ScrollElement name="contact">
      <section className={styles.contact}>
        <Reveal>
          <a href="mailto:hello@firstchild.co">hello@firstchild.co</a>
        </Reveal>
      </section>
    </ScrollElement>
  )
}

export default Contact
