import React from "react"

import SEO from "../components/seo"
import ProjectList from "../components/projectList"
import About from "../components/about"
import Partners from "../components/partners"
import Contact from "../components/contact"

const IndexPage = ({ location }) => {
  return (
    <>
      <SEO title="First Child | Design-minded web development" />
      <ProjectList />
      <About />
      <Partners />
      <Contact />
    </>
  )
}

export default IndexPage
