import React from "react"
import { motion } from "framer-motion"
import cx from "classnames"
import SVG from "react-inlinesvg"
import Arrow from "../images/svg/arrow.svg"

import styles from "./projectList.module.css"
import projects from "../../content/projects.json"

const ProjectList = () => {
  const list = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.6,
        when: "beforeChildren",
        staggerChildren: 0.05,
        staggerDirection: 1,
        delayChildren: 0,
      },
    },
  }
  const item = {
    hidden: {
      opacity: 0,
      y: -36,
      x: 0,
      rotate: 1,
    },
    show: {
      opacity: 1,
      y: 0,
      x: 0,
      rotate: 0,
      transition: {
        duration: 1.5,
        ease: "easeOut",
        type: "spring",
      },
    },
  }

  return (
    <section className={styles.projectList}>
      <motion.ul variants={list} initial="hidden" animate="show">
        {projects.map((project, index) => {
          return (
            <motion.li
              key={index}
              variants={item}
              className={styles.projectItem}
            >
              <a
                className={styles.projectLink}
                href={project.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {project.clientLogo && (
                  <div className={styles.clientLogo}>
                    <SVG src={`/${project.clientLogo}`} />
                  </div>
                )}
                <span className={styles.projectTitle}>
                  {project.project ? project.project : project.client}
                  <Arrow className={styles.arrow} />
                </span>
              </a>
              <ul className={styles.projectDetails}>
                {project.design && (
                  <li
                    className={cx(
                      styles.projectDetailItem,
                      styles.designCredit
                    )}
                  >
                    <span className={cx(styles.label, "mono")}>Design</span>
                    {project.designUrl ? (
                      <a
                        className={cx(styles.designLink, styles.credit)}
                        href={project.designUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {project.design}
                      </a>
                    ) : (
                      <span className={styles.credit}>{project.design}</span>
                    )}
                  </li>
                )}
                {project.tech && (
                  <li
                    className={cx(styles.projectDetailItem, styles.techNotes)}
                  >
                    <span className={cx(styles.label, "mono")}>Tech</span>
                    <span className={styles.credit}>{project.tech}</span>
                  </li>
                )}
              </ul>
            </motion.li>
          )
        })}
      </motion.ul>
    </section>
  )
}

export default ProjectList
